import { FunctionComponent } from "react"
import styled from "@emotion/styled"
import {
  colours,
  vertical,
  horizontal,
  typographyStyles,
  CancelIcon,
  breakpoints,
  iconSize,
} from "../../../index"

const StyledError = styled.div`
  ${typographyStyles.bodySmall};
  color: ${colours.destructive.main};
  text-align: left;
  display: flex;
  align-items: flex-start;
  margin-top: ${vertical.xxs};

  > svg {
    height: 20px;
    min-width: 16px;
  }

  ${breakpoints.tablet`
    > svg {
      height: 24px;
      min-width: 16px;
    }
  `}
`

const Text = styled.span`
  align-self: center;
  margin-left: ${horizontal.xs};
`

type Error = FunctionComponent<{
  message: string
  className?: string
}>

const Error: Error = ({ message, className }) => (
  <StyledError className={className}>
    <CancelIcon
      width={iconSize.xs}
      height={iconSize.xs}
      color={colours.destructive.main}
    />
    <Text>{message}</Text>
  </StyledError>
)

export default Error

import React from "react"

import styled from "@emotion/styled"

import { vertical, typographyStyles } from "../../../index"

const Wrapper = styled.span`
  display: flex;
  flex-direction: column;
  margin-bottom: ${vertical.xs};
`

const Title = styled.span`
  ${typographyStyles.bodyBold};
`

const Description = styled.span`
  ${typographyStyles.body};
  margin-top: ${vertical.xxs};
  white-space: pre-line;
`

interface Props {
  title: string
  description?: string | React.ReactElement
}

const QuestionText: React.FunctionComponent<Props> = ({
  title,
  description,
}) => (
  <React.Fragment>
    {!title && !description ? null : (
      <Wrapper>
        {title && <Title>{title}</Title>}
        {description && <Description>{description}</Description>}
      </Wrapper>
    )}
  </React.Fragment>
)

export default QuestionText

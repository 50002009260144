import React from "react"
import { css } from "@emotion/react"
import styled from "@emotion/styled"
import { colours, horizontal, typographyStyles } from "../../index"
import { mixins } from "../../mixins"
import Error from "./Internals/Error"
import { defaultSameLayoutString, Layout } from "../../variables/layout/Layout"
import QuestionText from "./Internals/QuestionText"
import { Hint, InputWidth } from "./Inputs/Shared"
import { breakpoints } from "../../variables/layout/Breakpoints"

const borderRadius = 8

const Container = styled.div``

const Label = styled.label`
  display: flex;
  flex-direction: column;
`

const InputWrapper = styled.span<{ hint?: Hint; isError: boolean }>`
  ${typographyStyles.bodySmall};
  height: 48px;
  display: flex;
  ${({ hint }) =>
    hint && hint.kind === "left"
      ? `
    flex-direction: row-reverse;
    justify-content: flex-end;
    `
      : ""};
  box-sizing: border-box;

  border-radius: ${borderRadius}px;

  ${({ isError }) => (isError ? ` ${mixins.error}; ` : "")};

  &:focus-within {
    ${mixins.focused};
  }
`

const HintElement = styled.span<{ hint?: Hint }>`
  min-width: 48px;
  height: 48px;
  padding: 0 ${horizontal.xs};
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  color: ${colours.white};
  border: 1px solid ${colours.offBlack};
  box-sizing: border-box;
  background-color: ${colours.offBlack};
  z-index: 1;

  ${({ hint }) =>
    hint?.transparent &&
    `
    color: inherit;
    background-color: ${colours.white};
  `}

  ${({ hint }) =>
    hint && hint.kind === "left"
      ? `
        border-top-left-radius: ${borderRadius}px;
        border-bottom-left-radius: ${borderRadius}px;
        ${hint.transparent ? "border-right: 0;" : ""}
      `
      : ""};
  ${({ hint }) =>
    hint && hint.kind === "right"
      ? `
        border-top-right-radius: ${borderRadius}px;
        border-bottom-right-radius: ${borderRadius}px;
        ${hint.transparent ? "border-left: 0;" : ""}
      `
      : ""};
`

const InputElement = styled.input<{
  hint?: Hint
  layout: Layout<InputWidth>
}>`
  ${typographyStyles.body};
  ${({ layout }) => css`
    ${layout.mobile === "half-width" ? "width: 50%" : "flex: 1"};
    ${breakpoints.tablet`
        ${layout.tablet === "half-width" ? "width: 50%" : "flex: 1"};
      `}

    ${breakpoints.desktop`
        ${layout.desktop === "half-width" ? "width: 50%" : "flex: 1"};
      `}
  `}
  margin: 0;
  padding: 0 ${horizontal.xs};
  outline: none;
  -webkit-appearance: none;
  border: 1px solid ${colours.offBlack};
  border-radius: ${borderRadius}px;
  box-sizing: border-box;
  height: 48px;

  ${({ hint }) =>
    hint && hint.kind === "left"
      ? `
      border-left: 0;
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
    `
      : ""}

  ${({ hint }) =>
    hint && hint.kind === "right"
      ? `
      border-right: 0;
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    `
      : ""}

  &:disabled {
    background-color: ${colours.greyScale.grey25};
    + span:after {
      background-color: ${colours.greyScale.grey25};
    }
  }
`

const naughtyChars = /\0/g

interface InputProps {
  // https://developer.mozilla.org/en-US/docs/Web/HTML/Element/input#%3Cinput%3E_types
  type: "email" | "password" | "search" | "tel" | "text" | "number"
  // https://developer.mozilla.org/en-US/docs/Web/HTML/Global_attributes/inputmode
  inputMode: "text" | "decimal" | "numeric" | "tel" | "search" | "email"

  ariaDescribedBy?: string
  // https://developer.mozilla.org/en-US/docs/Web/HTML/Attributes/autocomplete
  autoComplete?: string
  disabled?: boolean
  hint?: Hint
  id?: string
  dataTestId?: string
  inputRef?: React.RefObject<HTMLInputElement>
  isError?: boolean
  onBlur?: (evt: React.SyntheticEvent) => void
  onFocus?: (evt: React.SyntheticEvent) => void
  onInput?: (value: string) => void
  onKeyDown?: (evt: React.KeyboardEvent<HTMLInputElement>) => void
  placeholder?: string
  required?: boolean
  value?: string
  layout?: InputWidth | Layout<InputWidth>
  wipeNaughtyChars?: boolean

  // The below are valid for only some input types
  max?: number
  min?: number
  maxLength?: number
  minLength?: number
  step?: number
}

export const Input: React.FC<InputProps> = props => {
  const {
    dataTestId,
    placeholder = "",
    value = "",
    disabled = false,
    required = false,
    ariaDescribedBy = undefined,
    id = undefined,
    isError = false,
    layout = "full-width",
    wipeNaughtyChars = true,
  } = props

  const parsedLayout = defaultSameLayoutString(layout)

  return (
    <InputWrapper hint={props.hint} isError={isError}>
      <InputElement
        data-testid={dataTestId}
        aria-describedby={ariaDescribedBy}
        autoComplete={props.autoComplete}
        disabled={disabled}
        hint={props.hint}
        id={id}
        max={props.max}
        maxLength={props.maxLength}
        min={props.min}
        minLength={props.minLength}
        onBlur={evt => props.onBlur && props.onBlur(evt)}
        onChange={evt =>
          props.onInput &&
          props.onInput(
            wipeNaughtyChars
              ? (evt.target.value ?? "").replace(naughtyChars, "")
              : evt.target.value
          )
        }
        onFocus={evt => props.onFocus && props.onFocus(evt)}
        onKeyDown={evt => props.onKeyDown && props.onKeyDown(evt)}
        inputMode={props.inputMode}
        placeholder={placeholder}
        ref={props.inputRef}
        required={required}
        spellCheck={false}
        step={props.step}
        type={props.type}
        value={
          wipeNaughtyChars ? value && value.replace(naughtyChars, "") : value
        }
        layout={parsedLayout}
      />
      {props.hint &&
      (props.hint.kind === "right" || props.hint.kind === "left") ? (
        <HintElement hint={props.hint}>{props.hint.text}</HintElement>
      ) : null}
    </InputWrapper>
  )
}

/**
 * "Input questions" combine the input field with question text
 */
interface QuestionProps {
  title: string
  description?: string
  error?: string
  inputComponent: React.ReactElement
}

export const InputQuestion: React.FC<QuestionProps> = ({
  inputComponent,
  title,
  description,
  error,
}) => {
  return (
    <Container>
      <Label>
        <QuestionText title={title} description={description} />
        {inputComponent}
      </Label>
      {error ? <Error message={error} /> : null}
    </Container>
  )
}
